<template>
  <div class="text-center">
    <ion-icon class="hero-success-icon" name="alert"></ion-icon>

    <h2>Unauthorized</h2>
    <p>You do not have permission to view this page. Please contact your administrator if you believe this is in error.</p>
    <p><router-link to="/">Back to home page</router-link></p>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { alert } from "ionicons/icons";

addIcons({
  "ios-alert": alert.ios,
  "md-alert": alert.md
});

export default {
  name: "Success"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-success-icon {
  margin-top: 80px;
  font-size: 100px;
  color: #b40000;
}
</style>
